import frFR from './i18n/messages/fr-fr.common.json';
const messages = {
  fr: frFR,
};

export const locales = ['fr-fr'].map((locale) => {
  const languageCode = locale.split('-')[0];
  return {
    code: languageCode,
    iso: locale,
    corporate: languageCode,
  };
});

export default {
  locales,
  defaultLocale: 'fr',
  strategy: 'prefix_except_default',
  lazy: false,
  detectBrowserLanguage: {
    useCookie: false,
  },
  messages,
  warnHtmlInMessage: 'off',
  compilation: {
    strictMessage: false,
  },
};
